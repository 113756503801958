import React, {Component} from "react";
import Particles from "react-particles-js";
import Progress from "../progress";
import {Row, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faMedium,
    faLinkedin,
    faTwitter,
    faFacebook,
    faInstagram,
    faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import ThemeContext from "../../contexts/ThemeContext";
import "./styles.scss";

class Hero extends Component {
    static contextType = ThemeContext;

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    particles: {
                        number: {
                            value: 50,
                            density: {
                                enable: false,
                                value_area: 5000,
                            },
                        },
                        line_linked: {
                            enable: true,
                            opacity: 0.5,
                        },
                        size: {
                            value: 1,
                        },
                    },
                    retina_detect: true,
                }}
            />
        );
    }

    render() {
        return (
            <section
                id={`${this.props.id}`}
                className="about"
                style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>I’m a Software Development Engineer based in Istanbul, Turkey.</h3>
                            <div className="separator"/>
                            <p>
                                10+ years experience in web development. Proficient in designing system architecture , coding and testing applications. Interested in ASP.Net, Object-Oriented Programming, SOLID Principles, Test Driven Development, Design Patterns, Micro Services Architecture, React and DevOps practices. Very passionate about following new technologies. 
                            </p>
                            <div className="social social_icons">
                                <FontAwesomeIcon
                                    icon={faGithub}
                                    className="social_icon"
                                    onClick={() => window.open("https://github.com/umayeras")}
                                />
                                <FontAwesomeIcon
                                  icon={faMedium}
                                  className="social_icon"
                                  onClick={() => window.open("https://medium.com/@umayeras")}
                                />
                                <FontAwesomeIcon
                                    icon={faLinkedin}
                                    className="social_icon"
                                    onClick={() =>
                                        window.open("https://www.linkedin.com/in/umayeras")
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faTwitter}
                                    className="social_icon"
                                    onClick={() => window.open("https://twitter.com/umayeras")}
                                />
                                <FontAwesomeIcon
                                    icon={faFacebook}
                                    className="social_icon"
                                    onClick={() =>
                                        window.open("https://www.facebook.com/umayeras")
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faInstagram}
                                    className="social_icon"
                                    onClick={() => window.open("http://instagram.com/umayeras")}
                                />{" "}
                                <FontAwesomeIcon
                                    icon={faPinterest}
                                    className="social_icon"
                                    onClick={() => window.open("http://tr.pinterest.com/ueras")}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                        <div className="line-text">
                            <h4>My Skills</h4>
                        </div>
                        <div className="skills-container">
                            <Progress name="C#, ASP.Net MVC, ASP.Net Core" value={95} delay={600}/>
                            <Progress name="HTML5, CSS3, Bootstrap" value={95} delay={700}/>
                            <Progress name="Javascript, JQuery, React" value={88} delay={800}/>
                            <Progress name="EF, EF Core, Dapper" value={90} delay={900}/>
                            <Progress name="SQL, T-SQL, MongoDB" value={85} delay={1000}/>
                            <Progress name="WebAPI, RESTful WebServices, SOA" value={85} delay={1050}/>
                            <Progress name="Design Patterns, SOLID Principles" value={85} delay={1100}/>
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default Hero;
