import React, {Component} from "react";
import "./styles.scss";
import {Row, Col} from "react-bootstrap";
import BaffleText from "../baffle-text";
import ThemeContext from "../../contexts/ThemeContext";
import Form from "./form";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
        this.show = this.show.bind(this);
    }

    static contextType = ThemeContext;

    show() {
        this.setState({show: true});
    }

    form() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <Form/>
            );
        }
    }

    render() {
        return (
            <section
                id={`${this.props.id}`}
                className="contact"
                style={{height: this.context.height}}>
                <Row>
                    <Col md={2} className="side">
                        <h2>
                            <BaffleText
                                text="Contact"
                                revealDuration={500}
                                revealDelay={500}
                                parentMethod={this.show}
                                callMethodTime={1100}
                            />
                        </h2>
                    </Col>
                    <Col md={10} className="form">
                        {this.form()}
                    </Col>
                </Row>
            </section>
        );
    }
}

export default Contact;
