import React from "react";
import AnimationContainer from "../animation-container/index";
import {useForm} from "react-hook-form";
import {sendContactForm} from "../../actions/contactActions";
import {showErrorAlert, showSuccessAlert} from "./alert";

const Form = () => {
    const {register, handleSubmit, errors} = useForm();

    const onSubmit = (data, e) => {
        sendContactForm(data).then(result => {
            if (!result.data.isSuccess) {
                return showErrorAlert();
            } else {
                e.target.reset();
                return showSuccessAlert();
            }
        });
    };
    return (
        <AnimationContainer delay={0} animation="fadeInUp fast">
            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                <div className="line-text">
                    <h4>Get In Touch</h4>
                    <AnimationContainer delay={50} animation="fadeInUp fast">
                        <div className="form-group">
                            <input
                                ref={register({required: true, minLength: 3})}
                                name="NameSurname"
                                type="text"
                                className={`name ${errors.NameSurname && 'error'}`}
                                placeholder="Name Surname"
                            />
                        </div>
                    </AnimationContainer>
                    <AnimationContainer delay={100} animation="fadeInUp fast">
                        <div className="form-group">
                            <input
                                ref={register({required: true, pattern: /\S+@\S+\.\S+/})}
                                name="Email"
                                type="text"
                                className={`email ${errors.Email && 'error'}`}
                                placeholder="Email"
                            />
                        </div>
                    </AnimationContainer>
                    <AnimationContainer delay={150} animation="fadeInUp fast">
                        <div className="form-group">
                            <input
                                ref={register}
                                name="Phone"
                                type="text"
                                className="phone"
                                placeholder="Phone"
                            />
                        </div>
                    </AnimationContainer>
                    <AnimationContainer delay={200} animation="fadeInUp fast">
                        <div className="form-group">
                            <textarea
                                ref={register({required: true})}
                                name="Message"
                                className={`message ${errors.Message && 'error'}`}
                                placeholder="Message">
                            </textarea>
                        </div>
                    </AnimationContainer>
                    <AnimationContainer delay={250} animation="fadeInUp fast">
                        <div className="submit">
                            <button
                                type="submit"
                                className="hover-button">
                                <span>Send Message</span>
                            </button>
                        </div>
                    </AnimationContainer>
                </div>
            </form>
        </AnimationContainer>
    );
}

export default Form;