import React, { Fragment } from "react";
import Layout from "./components/layout";
import Spinner from "./components/spinner";
import Hero from "./components/hero";
import About from "./components/about";
//import Portfolio from "./components/portfolio";
import Contact from "./components/contact";

const App = () => {
  return (
    <Fragment>
      <Layout>
        <Hero id="home" />
        <About id="about" />
        {/*<Portfolio id="portfolio"/>*/}
        <Contact id="contact" />
      </Layout>
      <Spinner duration={1000} />
    </Fragment>
  );
};

export default App;
