import Swal from 'sweetalert2/src/sweetalert2.js';

export const showErrorAlert = () => {
  Swal.fire({
    icon: 'error',
    title: "Oops...",
    text: "Something went wrong!",
    confirmButtonColor: "#448aff",
  });
};

export const showSuccessAlert = () => {
  Swal.fire({
    icon: 'success',
    title: "Great...",
    text: "Your message has been sent...",
    confirmButtonColor: "#448aff",
  });
};
