import React, { Component } from "react";
import './styles.scss';

class Glitch extends Component {
    render() {
        const { text } = this.props
        return(
            <div className="glitch" data-text={text}>{text}</div>
        )
    }
}

export default Glitch;