import React, { Component } from "react";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import Typewriter from "typewriter-effect";
import Glitch from "../glitch";
import ThemeContext from "../../contexts/ThemeContext";
import UmayErasImage from "../../assets/images/umay.jpeg";
import UmayErasCV from "../../assets/umay-eras-cv.pdf";
class Hero extends Component {
  static contextType = ThemeContext;

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="hero"
        style={{ height: this.context.height }}>
        <Row>
          <Col md={6} className="content">
            <div className="content-text">
              <div className="line-text">
                <h4>Hello, I'm</h4>
              </div>
              <Glitch text="Umay Eras" />
              <Typewriter
                options={{
                  strings: [
                    "Software Development Engineer",
                    "Computer Engineeer",
                    "Full Stack Web Developer",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
              <a className="hover-button download-cv-button" href={UmayErasCV} target="_blank" rel="noopener noreferrer">
                <span>Download CV</span>
              </a>
            </div>
          </Col>
          <Col md={6} className="img">
            <img src={UmayErasImage} alt="umay eras" />
          </Col>
        </Row>
      </section>
    );
  }
}

export default Hero;
